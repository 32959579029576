export const zhL = {
    message: "你好",
    series: "系列",
    shape: "形",
    fret: "品",
    Fan: "扇",
    detail: "细节展示",
    xiistring :"十二弦",
    rare: "珍稀木材，尊贵藏品",
    vid: "视见 Gomans",
    ply: "吉他用家",
    sng: "歌曲",
    gal: "图册",
    vr: "Gomans VR 展厅",
    anoun: "声明",
    nav: {
        home: "主页",
        guitars: "所有乐器",
        dlx: "DLX 系列",
        ltd: "Gomans 特典",
        xiistring :"十二弦系列",
        ff: "十二品系列",
        artist: "演奏家",
        video: "视频",
        about: "关于我们",
    },
    footer: {
        video: "官方号",
        wx: "微信公众号",
        tb: "淘宝店",
        bili: "哔哩哔哩",
        contact: "联系我们",
        copyright: "广州高民木制品公司版权所有"
    },
    home: {
        title1: "技精则音至",
        desc1: "超十年经验积累，我们做出的吉他音色能够直触人心。",
        title2: "特挑木材",
        desc2: "每一片木材都要经过我们的严格筛选，才能被做进我们的Gomans吉他。",
        title3: "自研音梁",
        desc3: "我们立足传统，不断创新音梁的设计，至今从未止步。",
    },
    dlx: { 
        fea: "特点",
        brac: "镂空音梁",
        nec: "五拼一体琴柄",
        ash: "臂枕音孔",
        bdc: "斜角半切口",
        ssh: "侧面音孔",
    },
    ltd: {
        tit: "Gomans 特典",
        ltd: "限量版",
        spe: "特别版",
        sc: "Gomans与曹树堃合作系列"
    },
    xii: {
        desc: "十二弦吉他的音色饱满，高亮光泽，效果好过两把六弦吉他一起弹奏。音乐宽广，弹奏起来效果更好，并且更为深邃。所能诠释出来的便是六弦吉他无法弹奏的宽广音域。"
    },
    shapes: {
        bdl: "琴身长",
        shd: "肩宽",
        wai: "腰宽",
        tum: "肚宽",
        upt: "上厚",
        dot: "下厚",
        stl: "有效弦长",
        tot: "总长"
    },
    frets: {
        f12: "12 品",
        f16: "16 品",
        fFan: "扇品",
    },
    guitarProps: {
        series: "系列",
        name: "名字",
        model: "型号",
        shape: "琴型",
        pics: "",
        Top: "面板",
        BackSides: "背/侧",
        Neck: "琴柄",
        Fingerboard: "指板",
        Bridge: "琴桥",
        Nut: "上弦枕",
        Saddle: "下弦枕",
        TuningMachine: "弦准",
        String: "弦线",
        FinishTop: "面处理",
        FinishNeck: "柄处理",
        FinishBody: "鼓处理"
    },
    about: {
        int: "品牌介绍",
        intro: "Gomans Guitar是由法裔华人制琴师Tak Chan先生创立的。",
        wd0: "缘何 爱琴",
        wd1: "其声，松心",
        wd2: "其音，悦人",
        wd3: "以乐交友，如若高山流水",
        wd4: "",
        wd5: "",
        co: "广州高民木制品有限公司",
        addr: "中国广东省广州市从化区太平镇邓村四队16号",
        post: "510990",
        name: "徐小姐",
        tel: "电话：13926110081  微信同步",
        founder: "Gomans Guitar 创始人",
    },
    cust: {
        tit: "Gomans私人定制",
        shape: "桶型",
    },
    err: {
        pend: "这款吉他仍在开发中...",
        ret: "返回首页"
    }
}

export const enL = {
    message: "Hello",
    series: "SERIES",
    shape: "SHAPE",
    fret: "Fret",
    detail: "DETAILS",
    xiistring :"12 STRINGS",
    rare: "Made from Rare Wood",
    vid: "See Gomans",
    ply: "Player",
    sng: "Song",
    gal: "Gallery",
    vr: "Gomans VR Exhibition Hall",
    anoun: "Anouncement",
    nav: {
        home: "HOME",
        guitars: "ALL GUITARS",
        dlx: "DLX SERIES",
        ltd: "GOMANS SPECIAL",
        xiistring: "12 STRINGS SERIES",
        f12: "12 Fret",
        f16: "16 Fret",
        ff: "Fan-Fret",
        artist: "PLAYERS",
        video: "VIDEOS",
        about: "ABOUT US",
    },
    footer: {
        video: "FOLLOW US",
        wx: "WeChat Offcial Account",
        tb: "TaoBao Store",
        bili: "Bilibili",
        contact: "CONTACT US",
        copyright: "Copyright 2013 - 2023 © GomansGuitar.com All rights reserved."
    },
    home: {
        title1: "Accuracy for best sound ",
        desc1: "Precision measurements with very close tolerances.",
        title2: "Selected Wood",
        desc2: "We only use wooden pieces that has good grain, density and stiffness. ",
        title3: "Pro Designed Bracing",
        desc3: "Based on tradition, we kept innovating the formation of our bracing. ",
    },
    dlx: { 
        fea: "FEATURES",
        brac: "Drilled Holes Bracing",
        nec: "Five Pieces Neck",
        ash: "Armrest Sound Holes",
        bdc: "Bevel Demi Cutaway",
        ssh: "Side Sound Hole",
    },
    ltd: {
        tit: "Gomans Special",
        ltd: "Limited Collection",
        spe: "Special Collection",
        sc: "Gomans & Scott Cao"
    },
    xii: {
        desc: "A 12 string guitar has a full and shiny sound, It can give you a sound so wide that a six string guitar cannot have."
    },
    shapes: {
        bdl: "Body Length",
        shd: "Upper Bout Width",
        wai: "Waist Width",
        tum: "Lower Bout Width",
        upt: "Body Depth Neck",
        dot: "Body Depth Tail",
        stl: "Scale Length",
        tot: "Total Length"
    },
    frets: {
        f12: "12 Fret",
        f16: "16 Fret",
        fFan: "Fan Fret",
    },
    guitarProps: {
        series: "Series",
        name: "Name",
        model: "Model",
        shape: "Shape",
        pics: "",
        Top: "Top",
        BackSides: "BackSides",
        Neck: "Neck",
        Fingerboard: "Fingerboard",
        Bridge: "Bridge",
        Nut: "Nut",
        Saddle: "Saddle",
        TuningMachine: "Tuning Machine",
        String: "String",
        FinishTop: "Finish Top",
        FinishNeck: "Finish Neck",
        FinishBody: "Finish Body"
    },
    about: {
        int: "品牌介绍",
        intro: "Gomans Guitar was founded by Mr. Tak Chan, a French guitarist. ",
        wd0: "SOME WORDS FROM TAK",
        wd1: "Gomans Guitar was created because I have been loving to play guitar.",
        wd2: "A guitar that inspires my music",
        wd3: "A guitar that delivers my sound",
        wd4: "A guitar that touches my heart",
        wd5: "A guitar that fulfills my passion",
        co: "Guangzhou Gaomin (GOMANS) Wood Products Co. LTD",
        addr: "No.16, Team 4, Deng Cun, Taiping Town, Conghua District, Guangzhou city, Guangdong Province CHINA",
        addr2: "UNIT 705B, 7/F, JOIN-IN HANG SING CENTRE, 2-16 KWAI FUNG CRESCENT, KWAI CHUNG, N.T. HONG KONG.",
        post: "510990",
        name: "Mr Tak Chan",
        tel: "Tel (China):  (+86) 13926190577",
        tel2: "Tel (France):  (+33) 614714844",
        founder: "Founder of Gomans Guitar",
    },
    err: {
        pend: "This guitar is still under development...",
        ret: "Back to Home Page"
    }
}

export const frL = {
    message: "Bonjour",
    series: "Série",
    shape: "Série",
    detail: "Détails",
    xiistring :"12 STRINGS",
    rare: "Fait de bois rare",
    ply: "Player",
    // sng: "",
    gal: "Gallery",
    vr: "Gomans VR Exhibition Hall",
    nav: {
        home: "Accueil",
        guitars: "Guitare",
        dlx: "Série DLX",
        ltd: "Gomans Spécial",
        xiistring: "Série 12 STRINGS",
        ff: "Fan-Fret",
        artist: "Joueurs",
        video: "Vidéo",
        about: "À propos de nous",
    },
    footer: {
        video: "Suivez nous",
        bili: "Bilibili",
        contact: "Contactez nous",
        copyright: "Copyright 2013 - 2023 © GomansGuitar.com tous droits réservés."
    },
    home: {
        // title1: "Pro Designed Bracing",
        // desc1: "Our chief Tuning ",
        // title2: "Pro Designed Bracing",
        // desc2: "Our chief Tuning ",
        // title3: "Pro Designed Bracing",
        // desc3: "Our chief Tuning ",
    },
    dlx: {
        fea: "Fonctions",
        brac: "Drilled Holes Bracing",
        nec: "Five Pieces Neck",
        ash: "Armrest Sound Holes",
        bdc: "Bevel Demi Cutaway",
        ssh: "Side Sound Hole",
    },
    ltd: {
        tit: "Gomans Spécial",
        ltd: "Collections limitée",
        spe: "Collections spéciales",
    },
    xii: {
        desc: "A 12 string guitar has a full and shiny sound, It can give you a sound so wide that a six string guitar cannot have."
    },
    shapes: {
        // bdl: "琴身长",
        // shd: "肩宽",
        // wai: "腰宽",
        // tum: "肚宽",
        // upt: "上厚",
        // dot: "下厚",
        // stl: "有效弦长",
        // tot: "总长"
    },
    frets: {
        f12: "12 Fret",
        f16: "16 Fret",
        fFan: "Fan Fret",
    },
    guitarProps: {
        series: "Série",
        name: "Le nom",
        model: "Modèle",
        shape: "Série",
        pics: "",
        Top: "Table",
        BackSides: "Eclisses & dos",
        Neck: "Manche",
        Fingerboard: "Touche",
        Bridge: "Chevalet",
        Nut: "Sillet",
        Saddle: "Sillet chevalet",
        TuningMachine: "Mécaniques",
        String: "Corde",
        FinishTop: "Finition Table",
        FinishNeck: "Finition Manche",
        FinishBody: "Finition Corps"
    },
    about: {
        wd0: "SOME WORDS FROM TAK",
        wd1: "Gomans Guitar was created because I have been loving to play guitar.",
        wd2: "A guitar that inspires my music",
        wd3: "A guitar that delivers my sound",
        wd4: "A guitar that touches my heart",
        wd5: "A guitar that fulfills my passion",
        co: "Guangzhou Gaomin (GOMANS) Wood Products Co. LTD",
        addr: "No.16, Team 4, Deng Cun, Taiping Town, Conghua District, Guangzhou city, Guangdong Province CHINA",
        post: "510990",
        name: "Mr Tak Chan",
        tel: "Tel(China): (+86)13926190577",
        tel2: "Tel(France): (+33)614714844",
        founder: "Le fondateur de Gomans Guitar"
    },
    err: {
        pend: "Cette guitare est encore en développement...",
        ret: "Accueil"
    }
}
