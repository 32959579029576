
import TopMenu from '@/components/TopMenu'
import BottomNav from '@/components/BottomNav'
import ShapeIndi from '@/components/ShapeIndi'

// Vue.use({install(Vue)})
function install(Vue) {
    Vue.component(TopMenu.name, TopMenu)
    Vue.component(BottomNav.name, BottomNav)
    Vue.component(ShapeIndi.name, ShapeIndi)
}

export default {
    install
}
