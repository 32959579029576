<template>
    <el-container>
        <el-header>

            <div v-if="!isMobile" class="pc">
                <div class="left">
                    <a href="/">
                        <img class="logo" src="/logows.png">
                    </a>
                </div>
                <TopMenu />
                <div class="right">
                    <el-dropdown size="medium" :hide-on-click="true" trigger="click" @command="handleCommand">
                        <span class="el-dropdown-link">
                            Language<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="zh">简体中文</el-dropdown-item>
                            <el-dropdown-item command="en">English</el-dropdown-item>
                            <el-dropdown-item command="fr">Français</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>

            <div v-else class="mobile">
                <div class="left">
                    <button @click="toggleMenu"
                        :class="showMenu ? 'lines-button lines close' : 'lines-button lines'"><span></span></button>
                </div>
                <div class="logo-container">
                    <a href="/">
                        <img class="mlogo" src="/logows.png">
                    </a>
                </div>
                <div class="right">
                    <el-dropdown size="medium" :hide-on-click="true" trigger="click" @command="handleCommand">
                        <span class="el-dropdown-link">
                            Language<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="zh">简体中文</el-dropdown-item>
                            <el-dropdown-item command="en">English</el-dropdown-item>
                            <el-dropdown-item command="fr">Français</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </el-header>
        <el-main>
            <transition v-if="isMobile" name="menu-slide">
                <el-menu router v-show="showMenu" :default-active="$route.path" class="side-menu" text-color="#FFFFFF"
                    active-text-color="#ffffff" background-color="#000" @select="handleSelect">
                    <el-menu-item index="/">{{ $t('nav.home') }}</el-menu-item>
                    <el-menu-item index="/dlx">{{ $t('nav.dlx') }}</el-menu-item>
                    <el-menu-item index="/ltd">{{ $t('nav.ltd') }}</el-menu-item>
                    <el-submenu index="/guitars" class="guitars">
                        <template slot="title">{{ $t('nav.guitars') }}</template>
                        <el-menu-item v-for="shap in shaps" :key="shap.index" :index="shap.link">{{ shap.name }} {{
                            $t('shape') }}</el-menu-item>
                        <el-menu-item v-for="fret in fres" :key="fret.index" :index="fret.link">{{ $t('frets.f' + fret.name)
                        }}</el-menu-item>
                        <el-menu-item index="/xii">{{ $t('nav.xiistring') }}</el-menu-item>
                    </el-submenu>
                    <el-menu-item index="/videos">{{ $t('nav.video') }}</el-menu-item>
                    <el-menu-item index="/about">{{ $t('nav.about') }}</el-menu-item>
                </el-menu>
            </transition>
            <router-view></router-view>
        </el-main>
        <el-footer height="auto">
            <BottomNav />
        </el-footer>
    </el-container>
</template>
  
<script>
import { shapes } from '@/views/shape/shape';
import { frets } from '@/views/fret/fret';

export default {
    name: "layout",
    data() {
        return {
            showMenu: false,
            activeMenu: '',
            shaps: {},
            fres: {}
        };
    },
    methods: {
        toggleMenu() {
            this.showMenu = !this.showMenu;
        },
        handleSelect(index) {
            this.showMenu = !this.showMenu;
        },
        handleCommand(command) {
            this.$i18n.locale = command;
            window.localStorage.setItem("lang", this.$i18n.locale);
        }
    },
    computed: {
        isMobile() {
            return window.innerWidth <= 1135;
        },
    },
    created() {
        this.shaps = shapes;
        this.fres = frets;
        let lastlang = localStorage.getItem("lang");
        if (lastlang == 'en' || lastlang == 'zh' || lastlang == 'fr') {
            this.$i18n.locale = lastlang;
        }
        else {
            // 获取浏览器的语言
            var navilang = navigator.language;

            if (navilang.startsWith('zh')) {
                this.$i18n.locale = 'zh';
            } else if (navilang.startsWith('fr')) {
                this.$i18n.locale = 'fr';
            } else {
                this.$i18n.locale = 'en';
            }
            window.localStorage.setItem("lang", this.$i18n.locale);
        }
    }
}
</script>
  
  
<style scoped>
.el-container {
    background-color: black;
}

.el-header {
    background-color: rgb(0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.el-dropdown-menu {
    color: #fff;
    background-color: rgb(39, 40, 41);
    border: 0;
}

.el-dropdown-menu__item {
    color: #fff;
}

.el-dropdown-menu__item:not(.is-disabled):hover {
    color: #000;
    background-color: #fff;
}

.el-popper :deep() .popper__arrow {
    visibility: hidden;
}

.pc {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mobile {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pc.left {
    padding: 0 10px;
}

.mobile .left {
    width: 85px;
}

.logo {
    transform: scale(0.43);
    margin-top: 5px;
    max-width: 100%;
    max-height: 100%;
}

.mlogo {
    transform: scale(0.43);
    margin-top: 5px;
    max-width: 100%;
    max-height: 100%;
}

.el-menu-item {
    font-size: large;
    padding: 0 22px;
}

.el-submenu__icon-arrow {
    font-size: 28px;
}

.el-submenu__title i {
    color: #fff;
}

.el-dropdown-link {
    color: #eee;
}

.el-footer {
    background-color: rgb(28, 30, 32);
    color: #e2e2e2;
}

.el-col {
    text-align: left;
    vertical-align: middle;
    padding: 10px 0;
    height: 100%;
}

.menu-slide-enter-active,
.menu-slide-leave-active {
    transition: transform 0.33s ease-out;
}

.menu-slide-enter,
.menu-slide-leave-to {
    transform: translateY(-100%);
}

.el-main {
    background-color: #000;
    text-align: center;
    padding: 0;
    overflow: visible;
}

body>.el-container {
    margin-bottom: 40px;
}

.lines-button {
    position: relative;
    float: left;
    overflow: hidden;
    width: 36px;
    height: 38px;
    font-size: 0;
    border: none;
    cursor: pointer;
    -webkit-transition: background 0.3s;
    transition: background 0.3s;
}

.lines-button:focus {
    outline: none;
}

.lines-button span {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    height: 3px;
    background: #ddd;
}

.lines-button span::before,
.lines-button span::after {
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #ddd;
    content: "";
}

.lines-button span::before {
    top: -13px;
}

.lines-button span::after {
    bottom: -13px;
}

.lines {
    background: none;
}

.lines span {
    -webkit-transition: background 0s 0.3s;
    transition: background 0s 0.3s;
}

.lines span::before,
.lines span::after {
    -webkit-transition-duration: 0.3s, 0.3s;
    transition-duration: 0.3s, 0.3s;
    -webkit-transition-delay: 0.3s, 0s;
    transition-delay: 0.3s, 0s;
}

.lines span::before {
    -webkit-transition-property: top, -webkit-transform;
    transition-property: top, transform;
}

.lines span::after {
    -webkit-transition-property: bottom, -webkit-transform;
    transition-property: bottom, transform;
}

.lines.close {
    background: none;
}

.lines.close span {
    background: none;
}

.lines.close span::before {
    top: 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.lines.close span::after {
    bottom: 0;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.lines.close span::before,
.lines.close span::after {
    -webkit-transition-delay: 0s, 0.3s;
    transition-delay: 0s, 0.3s;
}
</style>