<template>
    <div class="BottomNav">
        <el-row class="siteMap" :gutter="20"> <!-- 栅格间隔 -->
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
                <h3>{{ $t('nav.dlx') }}</h3>
                <el-row v-for="dlx in dlxs" :key="dlx.index">
                    <a class="linq" @click="$router.push(dlx.link)">{{ dlx.model }}</a><br />
                </el-row>
            </el-col>

            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
                <h3>{{ $t('ltd.ltd') }}</h3>
                <el-row v-for="ltd in ltds" :key="ltd.index">
                    <a class="linq" @click="$router.push(ltd.link)">{{ ltd.model }}</a><br />
                </el-row>
                <h3>{{ $t('ltd.spe') }}</h3>
                <el-row v-for="spe in spes" :key="spe.index">
                    <a class="linq" @click="$router.push(spe.link)">{{ spe.model }}</a><br />
                </el-row>
            </el-col>

            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
                <h3>{{ $t('nav.guitars') }}</h3>
                <el-row v-for="shape in shaps" :key="shape.index">
                    <a class="linq" @click="$router.push(shape.link)">{{ shape.name }} {{ $t('shape') }}</a><br />
                </el-row>
                <el-row v-for="fret in fres" :key="fret.index">
                    <a class="linq" @click="$router.push(fret.link)">{{ $t('frets.f' + fret.name) }}</a><br />
                </el-row>
                <a class="linq" @click="$router.push('/xii')">{{ $t('nav.xiistring') }}</a><br />
            </el-col>

            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
                <h3>{{ $t('footer.video') }}</h3>
                <el-row class="acc" >
                    <el-col :span="12">
                        <img src="/wx.jpg" alt="">
                        <a class="linq" href="https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzI1NTE0Njk2Ng==&scene=124#wechat_redirect" target="_blank">{{ $t('footer.wx')
                        }} </a>
                    </el-col>
                    <el-col :span="12">
                        <img src="/tb.png" alt="">
                        <a class="linq" href="https://shop333682924.taobao.com/index.htm" target="_blank">{{ $t('footer.tb')
                        }} </a>
                    </el-col>
                </el-row>
                <a class="linq" href="https://space.bilibili.com/1457986941" target="_blank">{{ $t('footer.bili')
                }} </a> <br />
                <a class="linq" href="https://www.youtube.com/@GomansGuitar" target="_blank">YouTube</a>
                <div class="Contact Us">
                    <h3>{{ $t('footer.contact') }}</h3>
                    <span class="email">info@gomansguitar.com</span>
                </div>
            </el-col>
        </el-row>
        <hr class="breaker" />
        <div class="siteInfo">
            <el-row>
                <el-col :xs="24" :sm="8">
                    <img class="bottomlogo" src="/logows.png">
                </el-col>
                <el-col class="nfo" :xs="24" :sm="16">
                    <span class="copyright">{{ $t('footer.copyright') }}</span> <br />
                    <a class="gov" href="http://beian.miit.gov.cn/" target="_blank">粤ICP备18124712号</a>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import { dlxmodels } from '@/views/DLX/dlxmodels';
import { specials, limiteds } from '@/views/ltd/specials';
import { shapes } from '@/views/shape/shape';
import { frets } from '@/views/fret/fret';

export default {
    name: 'BottomNav',
    data() {
        return {
            dlxs: [],
            shaps: [],
            spes: null,
            ltds:null,
            fres: null
        }
    },
    created() {
        this.dlxs = dlxmodels;
        this.spes = specials;
        this.ltds = limiteds;
        this.shaps = shapes;
        this.fres = frets;
    }
}
</script>

<style scoped>
.BottomNav {
    width: 100%;
    max-width: 2200px;
}

a {
    margin: 6px 0;
    text-decoration: none;
    color: #aaa;
}

/* 状态一: 未被访问过的链接 */
a:link {
    margin: 6px 0;
    text-decoration: none;
    color: #aaa;
}

/* 状态二: 已经访问过的链接 */
a:visited {
    text-decoration: none;
    color: #aaa;
}

/* 状态三: 鼠标划过(停留)的链接(默认红色) */
a:hover {
    text-decoration: none;
    color: #fff;
}

a.gov:hover {
    text-decoration: none;
    color: #aaa;
    cursor: default;
}

/* 状态四: 被点击的链接 */
a:active {
    text-decoration: none;
    color: #fff;
}

h3 {
    margin-top: 28px;
}

.linq {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    position: relative;
    text-decoration: none;
    outline: 0;
    cursor: pointer;
    padding: 0;
    font-size: 14px;
    font-weight: 500;
    color: #aaa;
    transition: all .18s ease-out;
}

.siteMap {
    padding: 8px 38px;
    text-shadow: 1px 1px 6px #111;
}

.el-col {
    text-align: left;
    vertical-align: middle;
    padding: 0;
    height: 100%;
}

.acc {
    padding: 5px 0;
}

.acc img {
    width: 83%;
}

.email {
    font-size: 15px;
    font-weight: 500;
    color: #bbb;
}

.breaker {
    border: none;
    border-top: 1px solid #494949;
}

.siteInfo {
    padding: 0 28px;
}

.bottomlogo {
    width: 50px;
    height: 50px;
    padding-left: 18px;
    margin: 0;
}

.nfo {
    text-align: right;
}

.copyright {
    font-size: small;
    color: #999;
}

.gov {
    display: inline-flex;
    font-size: small;
    color: #777;
    margin: 3px 0;
}

@media screen and (max-width: 767px) {
    .el-col {
        text-align: center;
    }
    .nfo {
        text-align: center;
    }
    .bottomlogo {
        margin: 18px 0;
        padding: 0;
    }
}
</style>
