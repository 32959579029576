export const frets = {

    f12: {
        name: "12",
        desc: {
            zh: "",
            en: "",
            fr: "",
        },
        link: "/guitars/fret/f12",
        models: [
            {
                model: "AH-S18",
                desc: {
                    zh: "",
                    en: "",
                    fr: ""
                },
                pic: "/AH-S18/nav.jpg",
                link: "/guitar/AH-S18"
            },
            {
                model: "AH12-S18",
                desc: {
                    zh: "",
                    en: "",
                    fr: ""
                },
                pic: "/AH12-S18/nav.jpg",
                link: "/guitar/AH12-S18"
            },
        ]
    },
    f16: {
        name: "16",
        desc: {
            zh: "",
            en: "",
            fr: "",
        },
        link: "/guitars/fret/f16",
        models: [
            {
                model: "GM-S22",
                desc: {
                    zh: "",
                    en: "",
                    fr: ""
                },
                pic: "/GM-S22/nav.jpg",
                link: "/guitar/GM-S22"
            },
        ]
    },
    ff: {
        name: "Fan",
        desc: {
            zh: "",
            en: "",
            fr: "",
        },
        link: "/guitars/fret/ff",
        models: [
            {
                model: "000-S32FAC",
                desc: {
                    zh: "",
                    en: "",
                    fr: ""
                },
                pic: "/000-S32FAC/nav.jpg",
                link: "/guitar/000-S32FAC"
            },
            {
                model: "F-S18FC",
                desc: {
                    zh: "",
                    en: "",
                    fr: ""
                },
                pic: "/F-S18FC/nav.jpg",
                link: "/guitar/F-S18FC"
            },
            {
                model: "F-S12FC-BK",
                desc: {
                    zh: "",
                    en: "",
                    fr: ""
                },
                pic: "/F-S12FC-BK/nav.jpg",
                link: "/guitar/F-S12FC-BK"
            },
            {
                model: "F-S21FHC",
                desc: {
                    zh: "",
                    en: "",
                    fr: ""
                },
                pic: "/F-S21FHC/nav.jpg",
                link: "/guitar/F-S21FHC"
            },
            {
                shape: "F",
                model: "F-S38FAC DLX",
                desc: {
                    zh: "",
                    en: "",
                    fr: ""
                },
                pic: "/F-S38FAC_DLX/nav.jpg",
                link: "/guitar/F-S38FAC_DLX"
            },
            {
                model: "G-S32FAC",
                desc: {
                    zh: "",
                    en: "",
                    fr: ""
                },
                pic: "/G-S32FAC/nav.jpg",
                link: "/guitar/G-S32FAC"
            },
            {
                model: "J-S18FC",
                desc: {
                    zh: "",
                    en: "",
                    fr: ""
                },
                pic: "/J-S18FC/nav.jpg",
                link: "/guitar/J-S18FC"
            },
            {
                model: "J-S19FC",
                desc: {
                  zh: "",
                  en: "",
                  fr: ""
                },
                pic: "/J-S19FC/nav.jpg",
                link: "/guitar/J-S19FC"
              },
            {
                model: "J-S38FAC DLX",
                desc: {
                    zh: "",
                    en: "",
                    fr: ""
                },
                pic: "/J-S38FAC_DLX/nav.jpg",
                link: "/guitar/J-S38FAC_DLX"
            },
            {
                model: "BJ-S19FDC",
                desc: {
                    zh: "",
                    en: "",
                    fr: ""
                },
                pic: "/BJ-S19FDC/nav.jpg",
                link: "/guitar/BJ-S19FDC"
            },
        ]
    },

    
}