export const specials = [
  {
    model: "000-S12HCS",
    desc: {
      zh: "",
      en: "",
      fr: ""
    },
    pic: "/000-S12HCS/navd.jpg",
    link: "/guitar/000-S12HCS"
  },
  {
    model: "000-S15HCS",
    desc: {
      zh: "",
      en: "",
      fr: ""
    },
    pic: "/000-S15HCS/navd.jpg",
    link: "/guitar/000-S15HCS"
  },
  {
    model: "000-SA21HCS",
    desc: {
      zh: "",
      en: "",
      fr: ""
    },
    pic: "/000-SA21HCS/navd.jpg",
    link: "/guitar/000-SA21HCS"
  },
  {
    model: "000-SA21HCSB",
    desc: {
      zh: "",
      en: "",
      fr: ""
    },
    pic: "/000-SA21HCSB/navd.jpg",
    link: "/guitar/000-SA21HCSB"
  },
  {
    model: "G-S12CS",
    desc: {
      zh: "",
      en: "",
      fr: ""
    },
    pic: "/G-S12CS/navd.jpg",
    link: "/guitar/G-S12CS"
  },
  {
    model: "G-S15CS",
    desc: {
      zh: "",
      en: "",
      fr: ""
    },
    pic: "/G-S15CS/navd.jpg",
    link: "/guitar/G-S15CS"
  },
  {
    model: "G-S18AC-KP",
    desc: {
      zh: "",
      en: "",
      fr: ""
    },
    pic: "/G-S18AC-KP/navd.jpg",
    link: "/guitar/G-S18AC-KP"
  },
  {
    model: "G-S21DCTB",
    desc: {
      zh: "",
      en: "",
      fr: ""
    },
    pic: "/G-S21DCTB/navd.jpg",
    link: "/guitar/G-S21DCTB"
  },
  {
    model: "G-SA21CSB",
    desc: {
      zh: "",
      en: "",
      fr: ""
    },
    pic: "/G-SA21CSB/navd.jpg",
    link: "/guitar/G-SA21CSB"
  },
  {
    model: "F-S21C",
    desc: {
      zh: "",
      en: "",
      fr: ""
    },
    pic: "/F-S21C/navd.jpg",
    link: "/guitar/F-S21C"
  },
];


export const limiteds = [
  {
    model: "000-S30AC",
    desc: {
      zh: "",
      en: "",
      fr: ""
    },
    pic: "/000-S30AC/navd.jpg",
    link: "/guitar/000-S30AC"
  },
  {
    model: "000-S31AC",
    desc: {
      zh: "",
      en: "",
      fr: ""
    },
    pic: "/000-S31AC/navd.jpg",
    link: "/guitar/000-S31AC"
  },
  {
    model: "000-S32FAC",
    desc: {
      zh: "",
      en: "",
      fr: ""
    },
    pic: "/000-S32FAC/navd.jpg",
    link: "/guitar/000-S32FAC"
  },
  {
    model: "000-S33AC",
    desc: {
      zh: "",
      en: "",
      fr: ""
    },
    pic: "/000-S33AC/navd.jpg",
    link: "/guitar/000-S33AC"
  },
  {
    model: "000-S35AC",
    desc: {
      zh: "",
      en: "",
      fr: ""
    },
    pic: "/000-S35AC/navd.jpg",
    link: "/guitar/000-S35AC"
  },
  {
    model: "G-S30AC",
    desc: {
      zh: "",
      en: "",
      fr: ""
    },
    pic: "/G-S30AC/navd.jpg",
    link: "/guitar/G-S30AC"
  },
  {
    model: "G-S31AC",
    desc: {
      zh: "",
      en: "",
      fr: ""
    },
    pic: "/G-S31AC/navd.jpg",
    link: "/guitar/G-S31AC"
  },
  {
    model: "G-S32FAC",
    desc: {
      zh: "",
      en: "",
      fr: ""
    },
    pic: "/G-S32FAC/navd.jpg",
    link: "/guitar/G-S32FAC"
  },
  {
    model: "G-S33AC",
    desc: {
      zh: "",
      en: "",
      fr: ""
    },
    pic: "/G-S33AC/navd.jpg",
    link: "/guitar/G-S33AC"
  },
  {
    model: "G-S35AC",
    desc: {
      zh: "",
      en: "",
      fr: ""
    },
    pic: "/G-S35AC/navd.jpg",
    link: "/guitar/G-S35AC"
  },
]

export const sc = [
  {
    model: "AH-S21",
    desc: {
      zh: "",
      en: "",
      fr: ""
    },
    pic: "/AH-S21SC/navd.jpg",
    link: "/guitar/AH-S21SC"
  },
  {
    model: "000-S18AC",
    desc: {
      zh: "",
      en: "",
      fr: ""
    },
    pic: "/000-S18ACSC/navd.jpg",
    link: "/guitar/000-S18ACSC"
  },
  {
    model: "D-S18AC",
    desc: {
      zh: "",
      en: "",
      fr: ""
    },
    pic: "/D-S18AC/navd.jpg",
    link: "/guitar/D-S18AC"
  },
]