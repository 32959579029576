<template>
    <div v-if="valid">
        <img :style="{ width: wid}" :src="purl" :alt="shape">
    </div>
</template>

<script>
export default {
    name: "ShapeIndi",
    props: {
        shape: {
            type: String,
            default: ''
        },
        width : { type: String, default: '118px' },
    },
    data() {
        return {
            shap: '',
            wid: '',
            purl: '',
            valid: false
        }
    },
    created() {
        this.shap = this.shape.toLowerCase();
        if (this.shap == '000' || this.shap == 'ah' || this.shap == 'baby' || this.shap == 'bj' || this.shap == 'd' || this.shap == 'f' || this.shap == 'g' || this.shap == 'j' || this.shap == 'gm') {
            this.valid = true
        }
        this.wid = this.width;
        this.purl = '/si2/' + this.shap + '.png';
    }
}
</script>

<style scoped>
img {
    width: 258px;
}
</style>
