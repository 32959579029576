<template>
    <div class="shapes">
        <div class="head">
            <el-row>
                <el-col class="headcol" :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="title">
                        <span class="smallt" v-if="$i18n.locale == 'fr'">{{ $t('series') }}</span>
                        <span class="bigt">{{ shape.name }}</span>
                        <span class="smallt" v-if="$i18n.locale != 'fr'">{{ $t('shape') }}</span>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="7">
                    <img class="shapeindi" :src="'/si2/' + shape.name.toLowerCase() + '.png'" :alt="shape.name"
                        @error.once="moveErrorImg">
                </el-col>
                <el-col class="headcol" :xs="24" :sm="24" :md="24" :lg="11">
                    <p class="desc">{{ shape.desc[$i18n.locale] }}</p>
                </el-col>
            </el-row>
            <div class="hr"></div>
            <el-row class="siz">
                <el-col v-for="(value, key) in shape.siz" :key="key" :xs="12" :sm="6" :md="3" :lg="3" :xl="3">
                    <p class="sizk">{{ $t("shapes." + key) }}</p>
                    <p class="sizv">{{ value }} mm</p>
                </el-col>
            </el-row>
        </div>
        <div class="cont">
            <el-row>
                <el-col v-for="model in shape.models" :key="model.index" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                    <div class="singleModel" @click="$router.push(model.link)"
                        :style="{ backgroundImage: 'url(' + model.pic + ')' }">
                        <h2>{{ model.model }}</h2>
                        {{ model.desc[$i18n.locale] }}
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import { shapes } from './shape.js';

export default {
    name: 'Shape',
    data() {
        return {
            shape: {}
        };
    },
    methods: {
        moveErrorImg(event) {
            event.currentTarget.style.display = "none";
            return true;
        }
    },
    beforeRouteUpdate(to, from, next) {
        document.title = "GomansGuitar";
        next();
        this.param = this.$route.params.param;
        this.param = this.param.replaceAll('0', 'o');
        if (this.param in shapes) {
            this.shape = shapes[this.param];
            document.title = "GomansGuitar     " + this.shape.name + "   " + this.$t('shape');
        }
        else {
            this.$router.push('/404');
        }
    },
    created() {
        // 获取请求的琴型
        this.param = this.$route.params.param;
        this.param = this.param.replaceAll('0', 'o');
        // 从shapes取出对应型号数据放入data
        if (this.param in shapes) {
            this.shape = shapes[this.param];
            document.title = "GomansGuitar     " + this.shape.name + "   " + this.$t('shape');
        }
        else {
            this.$router.push('/404');
        }
    },
    mounted() {
        if (this.$i18n.locale == 'zh') {
            document.getElementsByClassName('desc')[0].style['text-indent'] = "2em";
        }
    },
}
</script>

<style scoped>
.head {
    padding: 18px 8px;
    background: linear-gradient(275deg, #101312, #1f2324);
    color: #ffffff;
    text-shadow: 1px 1px 6px #020202;
}

.shapeindi {
    height: 83px;
    margin: 8px 88px;
}

.bigt {
    font-size: 10ch;
    letter-spacing: 5px;
}

.smallt {
    font-size: 3ch;
}

.desc {
    padding: 0 58px;
    margin: 0;
    line-height: 1.58;
    text-align: left;
    font-family: bahnschrift, "Helvetica Neue";
    font-size: 2ch;
}

.siz {
    padding: 0 0 0 5%;
    font-family: AGaramondPro, "Helvetica Neue";
}

.sizk {
    margin: 3px 0;
    padding-top: 16px;
    line-height: 1.1;
    text-align: left;
}

.sizv {
    margin: 4px auto;
    line-height: 1.1;
    text-align: left;
    font-size: large;
}

.cont {
    background-color: #0c0c0d;
}

.singleModel {
    aspect-ratio: 0.52;
    padding: 0 25px;
    color: #ffffff;
    text-align: left;
    text-shadow: 1px 1px 6px #111;
    position: relative;
    border: solid 1px #111111;
    box-sizing: border-box;
    z-index: 1;
    cursor: pointer;
    background-color: #000;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: local;
    background-size: 95% auto;
    -webkit-transition: all .58s ease;
    transition: all .58s ease;
}

.singleModel:hover {
    background-size: 100% auto;
    -webkit-transition: all .58s ease;
    transition: all .58s ease;
}

h2 {
    font-size: 3.6ch;
    margin: 28px 8px;
}

.hr {
    height: 1px;
    width: 98%;
    margin: 8px auto;
    border: none;
    background: linear-gradient(158deg, rgba(0, 0, 0, 0), aliceblue, rgba(0, 0, 0, 0));
}

@media screen and (max-width: 991px) {
    .desc {
        margin: 18px 0;
    }

    .shapeindi {
        margin: 18px auto;
    }

    h2 {
        font-size: 3.1ch;
        margin: 18px 0;
    }
}
</style>
