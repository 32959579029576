export const dlxmodels = [
  {
      model: "000-S18AC DLX",
      desc: {
          zh: "",
          en: "",
          fr: ""
      },
      pic: "/000-S18AC_DLX/navd.jpg",
      link: "/guitar/000-S18AC_DLX"
  },
  {
    model: "F-S38FAC DLX",
    desc: {
      zh: "",
      en: "",
      fr: ""
    },
    pic: "/F-S38FAC_DLX/navd.jpg",
    link: "/guitar/F-S38FAC_DLX"
  },
  {
    model: "GC-S19AC DLX",
    desc: {
      zh: "",
      en: "",
      fr: ""
    },
    pic: "/GC-S19AC_DLX/navd.jpg",
    link: "/guitar/GC-S19AC_DLX"
  },
  {
    model: "G-S22AC DLX",
    desc: {
      zh: "",
      en: "",
      fr: ""
    },
    pic: "/G-S22AC_DLX/navd.jpg",
    link: "/guitar/G-S22AC_DLX"
  },
  {
    model: "GC-S38AC DLX",
    desc: {
      zh: "",
      en: "",
      fr: ""
    },
    pic: "/GC-S38AC_DLX/navd.jpg",
    link: "/guitar/GC-S38AC_DLX"
  },
  {
    model: "GC-S88AC DLX",
    desc: {
      zh: "",
      en: "",
      fr: "",
    },
    pic: "/GC-S88AC_DLX/navd.jpg",
    link: "/guitar/GC-S88AC_DLX",
  },
  {
    model: "J-S38AC DLX",
    desc: {
      zh: "",
      en: "",
      fr: ""
    },
    pic: "/J-S38AC_DLX/navd.jpg",
    link: "/guitar/J-S38AC_DLX"
  },
  {
    model: "J-S38FAC DLX",
    desc: {
      zh: "",
      en: "",
      fr: ""
    },
    pic: "/J-S38FAC_DLX/navd.jpg",
    link: "/guitar/J-S38FAC_DLX"
  },
  {
    model: "BJ-S21DC DLX",
    desc: {
      zh: "",
      en: "",
      fr: ""
    },
    pic: "/BJ-S21DC_DLX/navd.jpg",
    link: "/guitar/BJ-S21DC_DLX"
  },
]
