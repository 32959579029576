<template>
  <div id="app" class="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  created() {
    let lastlang = localStorage.getItem("lang");
    if (lastlang == 'en' || lastlang == 'zh' || lastlang == 'fr') {
      this.$i18n.locale = lastlang;
    }
    else {
      // 获取浏览器的语言
      var navilang = navigator.language;

      if (navilang.startsWith('zh')) {
        this.$i18n.locale = 'zh';
      } else if (navilang.startsWith('fr')) {
        this.$i18n.locale = 'fr';
      } else {
        this.$i18n.locale = 'en';
      }
      window.localStorage.setItem("lang", this.$i18n.locale);
    }
  }
}
</script>

<style scoped>
#app {
  /* Avenir, Helvetica, Arial, sans-serif,  */
  font-family: "Microsoft YaHei", "微软雅黑", "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  background-color: #000;
  height: 100%;
  width: 100%;
}
</style>